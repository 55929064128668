import "core-js/modules/es.array.push.js";
import { get } from './../api';
export default {
  data() {
    return {
      urls: ['river', 'dialect', 'interact'],
      config: {},
      to: '',
      assetsUrl: window.assetsUrl,
      width: '',
      height: ''
    };
  },
  mounted() {
    document.title = '知识互动';
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    this.getWindowInfo();
    this.getHome();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
  },
  methods: {
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    back() {
      this.$router.push('/');
    },
    toUrl(to) {
      this.to = to;
      setTimeout(() => {
        this.$router.push(to);
      }, 600);
    },
    getHome() {
      get('home').then(res => {
        console.log(res);
        this.config = res.data;
      });
    }
  }
};